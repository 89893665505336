/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-unused-vars */
// WebPack generation of HTML files under `/demo`
// import './demo/index.html';
import React from 'react';
import { AudiPlatformProvider, audiDarkTheme } from '@audi/audi-ui-react-v2';
import { FeatureAppDefinition, FeatureServices, Logger } from '@feature-hub/core';
import { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import { ReactFeatureApp } from '@feature-hub/react';
import OneLayerCollection from './components/one-layer-collection';

export const FEATURE_APP_NAME = 'oneaudi-feature-app-one-layer';

export interface Dependencies extends FeatureServices {
  readonly 's2:logger': Logger;
  readonly 'layer-manager': LayerManagerV25;
}

interface Config {
  zIndexAlert: number;
  zIndex?: number;
}

type FeatureAppDefinitionType = FeatureAppDefinition<ReactFeatureApp, Dependencies, Config>;

const featureAppDefinition: FeatureAppDefinitionType = {
  create: ({ featureServices: services, config, featureAppId }) => {
    const zIndexAlert = config?.zIndexAlert || 100;
    const zIndex = config?.zIndex;

    return {
      // disable eslint rule because this is a (Feature Hub) React Feature App,
      // that doesn't define a `displayName` attribute
      // eslint-disable-next-line react/display-name
      render: (): React.ReactNode => (
        <AudiPlatformProvider theme={audiDarkTheme}>
          <OneLayerCollection
            {...{ featureAppId, zIndex, zIndexAlert }}
            layerManager={services['layer-manager']}
          />
        </AudiPlatformProvider>
      ),
    };
  },

  dependencies: {
    externals: { react: '^16.13.0 || ^17.0.2', '@audi/audi-ui-react-v2': '^2' },
    featureServices: {
      'layer-manager': '^2.5.0',
      's2:logger': '^1.0.0',
    },
  },

  optionalDependencies: {
    featureServices: {},
  },
};

export default featureAppDefinition;
