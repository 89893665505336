import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { audiDarkTheme } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { ReactComponent as CloseButtonSvg } from '../assets/cancel-small.svg';

const { m: breakpointM } = audiDarkTheme.breakpoints;

const CloseButton = styled.button`
  align-items: center;
  color: #000;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  width: 48px;
  border: none;
  background: none;
  @media (min-width: ${breakpointM}px) {
    position: absolute;
    top: 0;
  }
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

interface IClose {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose: (e: any) => void;
}

const Close: React.FC<IClose> = (props) => {
  const { onClose } = props;
  return (
    <CloseButtonWrapper>
      <CloseButton
        aria-label="close button"
        data-testid="one-layer-close"
        onClick={onClose}
        role="button"
        title="close button"
      >
        <CloseButtonSvg />
      </CloseButton>
    </CloseButtonWrapper>
  );
};

export default Close;
