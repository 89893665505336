import React, { useCallback, useEffect, useRef } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce';
import { ALERT, LayerTypes } from './globals';
import OneLayerInner, { Animation, AnimationWrapper, ScrollableContent } from './one-layer-inner';
import Close from './close';
import OneLayerContent from './one-layer-content';
import OneLayerOuter from './one-layer-outer';

export interface IOneLayer {
  showCloseButton?: boolean;
  close: () => void;
  type: LayerTypes;
  zIndexAlert: number;
  zIndex?: number;
  active: boolean;
  setStartHideAnimation: (start: boolean) => void;
  hideAnimation: boolean;
  openAnimation: boolean;
  showAnimation: boolean;
  setScrollOffset: (id: string, n: number) => void;
  id: string;
  scrollOffset?: number;
  index: number;
}

export const OneLayer: React.FC<IOneLayer> = ({
  close,
  type,
  children,
  showCloseButton,
  zIndexAlert,
  zIndex,
  setStartHideAnimation,
  hideAnimation,
  openAnimation,
  showAnimation,
  active,
  setScrollOffset,
  id,
  scrollOffset,
  index,
}) => {
  const refOuter = React.useRef<HTMLDivElement | null>(null);
  const refFirstFocusable = React.useRef<HTMLElement | null>(null);
  const refLastFocusable = React.useRef<HTMLElement | null>(null);

  const ref = useRef(null);
  const layerContentContainerRef = useRef(null);

  useEffect(() => {
    const refCopy = ref.current;

    const listener = debounce((): void => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setScrollOffset(id, refCopy?.scrollTop);
    }, 250);

    if (active && refCopy) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refCopy.addEventListener('scroll', listener);
    }

    return (): void => {
      if (active && refCopy) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        refCopy.removeEventListener('scroll', listener);
      }
    };
  }, [ref, active]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    refOuter.current?.focus();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref.current.scrollTop = scrollOffset;
    if (
      children &&
      typeof children === 'function' &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      layerContentContainerRef.current instanceof HTMLElement
    ) {
      children(layerContentContainerRef.current);
    }
  }, []);

  const onLayerClickInner = useCallback((e): void => {
    e.stopPropagation();
  }, []);

  const onLayerClickOuter = useCallback(
    (e): void => {
      e.stopPropagation();
      setStartHideAnimation(true);
    },
    [setStartHideAnimation]
  );

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    const focusableElements = Array.from<HTMLElement>(
      refOuter.current?.querySelectorAll('a[href]:not([disabled]), button:not([disabled])') ?? []
    );

    [refFirstFocusable.current] = focusableElements;
    refLastFocusable.current = focusableElements[focusableElements.length - 1];

    if (document.activeElement === refLastFocusable.current && e.key === 'Tab' && !e.shiftKey) {
      e.preventDefault();
      refFirstFocusable.current?.focus();
    }
    if (document.activeElement === refFirstFocusable.current && e.key === 'Tab' && e.shiftKey) {
      e.preventDefault();
      refLastFocusable.current?.focus();
    }

    if (e.key === 'Escape') {
      close();
    }
  }, []);

  return (
    <OneLayerOuter
      data-fefa-layer-active="true"
      data-one-layer="true"
      data-testid={`one-layer-outer-${index}`}
      onClick={onLayerClickOuter}
      type={type}
      zIndex={type === ALERT ? zIndexAlert : zIndex}
    >
      <AnimationWrapper type={type}>
        <Animation
          className={openAnimation ? 'oneLayerOpenAnimation' : ''}
          data-testid="open-animation-wrapper"
        >
          <Animation
            className={showAnimation ? 'oneLayerShowAnimation' : ''}
            data-testid="show-animation-wrapper"
          >
            <Animation
              className={hideAnimation ? 'oneLayerHideAnimation' : ''}
              data-testid="hide-animation-wrapper"
              onAnimationEnd={(): void => {
                if (hideAnimation) {
                  setStartHideAnimation(false);
                  if (active) {
                    close();
                  }
                }
              }}
            >
              <OneLayerInner
                data-testid="one-layer-inner"
                onClick={onLayerClickInner}
                onMouseDown={onLayerClickInner}
                onTouchStart={onLayerClickInner}
                type={type}
                onKeyDown={onKeyDown}
                ref={refOuter}
                tabIndex={0}
              >
                {showCloseButton && <Close onClose={onLayerClickOuter} />}
                <ScrollableContent
                  data-scroll-context
                  data-testid="one-layer-scrollable-content"
                  ref={ref}
                >
                  <OneLayerContent
                    data-testid={`one-layer-content-${index}`}
                    ref={layerContentContainerRef}
                    type={type}
                  >
                    {typeof children !== 'function' ? children : null}
                  </OneLayerContent>
                </ScrollableContent>
              </OneLayerInner>
            </Animation>
          </Animation>
        </Animation>
      </AnimationWrapper>
    </OneLayerOuter>
  );
};
